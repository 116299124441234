import { Row, Col, Button } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';

const PoolListItem = ({ pool }) => {
  const {name, SK, tournamentName,tournamentStartDate} = pool;
  const startDate = new Date( Date.parse(tournamentStartDate));
  return (    
    <Row>
      <Col xs={3} sm={3} md={3} lg={3}>
        <p>{name}</p>
      </Col>
      <Col xs={3} sm={3} md={3} lg={3}>
        <p>{tournamentName}</p>
      </Col>
      <Col xs={3} sm={3} md={3} lg={3}>
        <p>{startDate.toDateString()}</p>
      </Col>
      <Col xs={3} sm={3} md={3} lg={3}>
      <Button variant="primary" href={`/Pools/${SK}`}>View Pool <ArrowRightCircle/></Button>
      </Col>
    </Row>
  );
};

export default PoolListItem;
