import { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ViewBracketData from "./ViewBracketData";

const ViewBracket = ({jwt, userId}) => {
    const {bracketId} = useParams();
    const [bracket, setBracket] = useState(null);
    const [bracketData, setBracketData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(()=>{
        const fetchBracket = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", jwt);
            
            const requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            
            const response = await fetch(`https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/brackets/${bracketId}`, requestOptions);
            const responseJson = await response.json();
            if (responseJson.length > 0) {
                setBracket(responseJson[0]);
            } else {
                setError('Error: Could not find this bracket.');
            }
        };  

        const fetchBracketData = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", jwt);

            const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            const response = await fetch(`https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/brackets/data/${bracketId}`, requestOptions);
            const responseJson = await response.json();
            if (responseJson.length > 0) {
                setBracketData(responseJson[0]['data']);
            } else {
                setError('Error: Could not load the picks for this bracket.');
            }
        };

        if(jwt !== null) {
            fetchBracket();
            fetchBracketData();
        }
    },[jwt, bracketId, error]);

    if (error !== null) {
        return (
            <Container>
                <Row>
                    <Col>
                        <p>{error}</p>
                    </Col>
                </Row>
            </Container>
        );
    } else if (bracket !== null && bracketData !== null) {
        return (
            <Container>
                <Row>
                    <Col>
                        <h1>{bracket.name}</h1>
                        <p>{bracket.tournamentName}</p>
                    </Col>
                </Row>
                <ViewBracketData data={bracketData}/>
            </Container>
        );
    } else {
        return <Spinner></Spinner>
    }
};

export default ViewBracket;