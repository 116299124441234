import { Button } from "@aws-amplify/ui-react";
import "./SignOutButton.css";

function SignOutButton({ signOut }) {  
  return (
    <div className="btn-signout ms-4">
      <Button onClick={signOut}>Sign out</Button>
    </div>
  );
}
  
  export default SignOutButton;