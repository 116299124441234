import { Container, Row, Col, Spinner } from "react-bootstrap";
import './ViewBracket.css';

const ViewBracketData = ({ data }) => {

    if (data !== null) {
        return (
            <Container>
                {/* row 1 */}
                <Row>
                    <Col><p>{data['GAME9']['TEAM1']}</p></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col><p>{data['GAME11']['TEAM1']}</p></Col>
                </Row>
                {/* row 2 */}
                <Row>
                    <Col></Col>
                    <Col><p>{data['GAME13']['TEAM1']}</p></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col><p>{data['GAME14']['TEAM1']}</p></Col>
                    <Col></Col>
                </Row>
                {/* row 3 */}
                <Row>
                    <Col><p>{data['GAME9']['TEAM2']}</p></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col><p>{data['GAME11']['TEAM2']}</p></Col>
                </Row>
                {/* Row 4 */}
                <Row>
                    <Col></Col>
                    <Col></Col>
                    <Col><p>{data['GAME15']['TEAM1']}</p></Col>
                    <Col><p>{data['WINNER']['TEAM1']}</p></Col>
                    <Col><p>{data['GAME15']['TEAM2']}</p></Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
                {/* Row 5 */}
                <Row>
                    <Col><p>{data['GAME10']['TEAM1']}</p></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col><p>{data['GAME12']['TEAM1']}</p></Col>
                </Row>
                {/* Row 6 */}
                <Row>
                    <Col></Col>
                    <Col><p>{data['GAME13']['TEAM2']}</p></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col><p>{data['GAME14']['TEAM2']}</p></Col>
                    <Col></Col>
                </Row>
                {/* Row 7 */}
                <Row>
                    <Col><p>{data['GAME10']['TEAM2']}</p></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col><p>{data['GAME12']['TEAM2']}</p></Col>
                </Row>
            </Container>
        );
    } else {
        return <Spinner></Spinner>
    }
};

export default ViewBracketData;