import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const BracketLayout = ({
  selectedPoolTeams,
  selectedPool,
  bracketName,
  userId,
  jwt,
  setSubmitForm,
}) => {
  const [selectedWinners, setSelectedWinners] = useState({
    game1: null,
    game2: null,
    game3: null,
    game4: null,
    game5: null,
    game6: null,
    game7: null,
    game8: null,
    game9: null,
    game10: null,
    game11: null,
    game12: null,
    game13: null,
    game14: null,
    game15: null,
  });
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedWinners((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    } else {
      e.preventDefault();
      setValidated(true);

      // const poolSelect = findPool();

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `${jwt}`);
      myHeaders.append("Content-Type", "application/json");

      const {
        tournamentId,
        tournamentName,
        tournamentStartDate,
        SK:poolId,
        name,
      } = selectedPool[0];

      const body = JSON.stringify({
        name: bracketName,
        tournamentId: tournamentId,
        tournamentName: tournamentName,
        tournamentStartDate: tournamentStartDate,
        poolId: poolId,
        poolName: name,
        user: userId,
        data: {
          GAME9: {
            TEAM1: selectedWinners.game1,
            TEAM2: selectedWinners.game2,
          },
          GAME10: {
            TEAM1: selectedWinners.game3,
            TEAM2: selectedWinners.game4,
          },
          GAME11: {
            TEAM1: selectedWinners.game5,
            TEAM2: selectedWinners.game6,
          },
          GAME12: {
            TEAM1: selectedWinners.game7,
            TEAM2: selectedWinners.game8,
          },
          GAME13: {
            TEAM1: selectedWinners.game9,
            TEAM2: selectedWinners.game10,
          },
          GAME14: {
            TEAM1: selectedWinners.game11,
            TEAM2: selectedWinners.game12,
          },
          GAME15: {
            TEAM1: selectedWinners.game13,
            TEAM2: selectedWinners.game14,
          },
          WINNER: {
            TEAM1: selectedWinners.game15,
          },
        },
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body,
      };

      const url =
        "https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/brackets/create";
      const response = await fetch(url, requestOptions);
      // eslint-disable-next-line
      const responseJson = await response.json();

      if (response.status === 200) {
        setSelectedWinners({
          game1: null,
          game2: null,
          game3: null,
          game4: null,
          game5: null,
          game6: null,
          game7: null,
          game8: null,
          game9: null,
          game10: null,
          game11: null,
          game12: null,
          game13: null,
          game14: null,
          game15: null,
        });
        setValidated(false);
        setSubmitForm(true);
        navigate('/Pools');
      } else {
        console.log(await response.json());
      }
    }
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit} style={{marginBottom: '50px'}}>
      <Container>
        <Row>
          <Col>
            <h6>Round of 8</h6>
          </Col>
          <Col>
            <h6>Quarterfinal</h6>
          </Col>
          <Col>
            <h6>Semifinal</h6>
          </Col>
          <Col>
            <h6>Championship</h6>
          </Col>
          <Col>
            <h6>Semifinal</h6>
          </Col>
          <Col>
            <h6>Quarterfinal</h6>
          </Col>
          <Col>
            <h6>Round of 8</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Game 1</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game1"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team1 === null ||
                selectedPoolTeams.team2 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team1}>
                {selectedPoolTeams.team1}
              </option>
              <option value={selectedPoolTeams.team2}>
                {selectedPoolTeams.team2}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            <h6>Game 5</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game5"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team9 === null ||
                selectedPoolTeams.team10 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team9}>
                {selectedPoolTeams.team9}
              </option>
              <option value={selectedPoolTeams.team10}>
                {selectedPoolTeams.team10}
              </option>
            </Form.Control>
          </Col>
        </Row>
        {/* row 2 */}
        <Row>
          <Col></Col>
          <Col>
            <h6>Game 9</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game9"
              onChange={handleChange}
              disabled={
                selectedWinners.game1 === null || selectedWinners.game2 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedWinners.game1}>
                {selectedWinners.game1}
              </option>
              <option value={selectedWinners.game2}>
                {selectedWinners.game2}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            <h6>Game 11</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game11"
              onChange={handleChange}
              disabled={
                selectedWinners.game5 === null || selectedWinners.game6 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedWinners.game5}>
                {selectedWinners.game5}
              </option>
              <option value={selectedWinners.game6}>
                {selectedWinners.game6}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
        </Row>
        {/* row 3 */}
        <Row>
          <Col>
            <h6>Game 2</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game2"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team3 === null ||
                selectedPoolTeams.team4 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team3}>
                {selectedPoolTeams.team3}
              </option>
              <option value={selectedPoolTeams.team4}>
                {selectedPoolTeams.team4}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            <h6>Game 6</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game6"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team11 === null ||
                selectedPoolTeams.team12 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team11}>
                {selectedPoolTeams.team11}
              </option>
              <option value={selectedPoolTeams.team12}>
                {selectedPoolTeams.team12}
              </option>
            </Form.Control>
          </Col>
        </Row>
        {/* Row 4 */}
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <h6>Game 13</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game13"
              onChange={handleChange}
              disabled={
                selectedWinners.game9 === null ||
                selectedWinners.game10 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedWinners.game9}>
                {selectedWinners.game9}
              </option>
              <option value={selectedWinners.game10}>
                {selectedWinners.game10}
              </option>
            </Form.Control>
          </Col>
          <Col>
            <h6>Winner:</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game15"
              onChange={handleChange}
              disabled={
                selectedWinners.game13 === null ||
                selectedWinners.game14 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedWinners.game13}>
                {selectedWinners.game13}
              </option>
              <option value={selectedWinners.game14}>
                {selectedWinners.game14}
              </option>
            </Form.Control>
            <Button type="submit" style={{marginTop: '10px'}}>Submit Bracket</Button>
          </Col>
          <Col>
            <h6>Game 14</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game14"
              onChange={handleChange}
              disabled={
                selectedWinners.game11 === null ||
                selectedWinners.game12 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedWinners.game11}>
                {selectedWinners.game11}
              </option>
              <option value={selectedWinners.game12}>
                {selectedWinners.game12}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        {/* Row 5 */}
        <Row>
          <Col>
            <h6>Game 3</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game3"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team5 === null ||
                selectedPoolTeams.team6 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team5}>
                {selectedPoolTeams.team5}
              </option>
              <option value={selectedPoolTeams.team6}>
                {selectedPoolTeams.team6}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            <h6>Game 7</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game7"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team13 === null ||
                selectedPoolTeams.team14 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team13}>
                {selectedPoolTeams.team13}
              </option>
              <option value={selectedPoolTeams.team14}>
                {selectedPoolTeams.team14}
              </option>
            </Form.Control>
          </Col>
        </Row>
        {/* Row 6 */}
        <Row>
          <Col></Col>
          <Col>
            <h6>Game 10</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game10"
              onChange={handleChange}
              disabled={
                selectedWinners.game3 === null || selectedWinners.game4 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedWinners.game3}>
                {selectedWinners.game3}
              </option>
              <option value={selectedWinners.game4}>
                {selectedWinners.game4}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            <h6>Game 12</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game12"
              onChange={handleChange}
              disabled={
                selectedWinners.game7 === null || selectedWinners.game8 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedWinners.game7}>
                {selectedWinners.game7}
              </option>
              <option value={selectedWinners.game8}>
                {selectedWinners.game8}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
        </Row>
        {/* Row 7 */}
        <Row>
          <Col>
            <h6>Game 4</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game4"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team7 === null ||
                selectedPoolTeams.team8 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team7}>
                {selectedPoolTeams.team7}
              </option>
              <option value={selectedPoolTeams.team8}>
                {selectedPoolTeams.team8}
              </option>
            </Form.Control>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            <h6>Game 8</h6>
            <Form.Control
              required
              as="select"
              type="select"
              name="game8"
              onChange={handleChange}
              disabled={
                selectedPoolTeams.team15 === null ||
                selectedPoolTeams.team16 === null
                  ? true
                  : false
              }
            >
              <option value="">Select Winner</option>
              <option value={selectedPoolTeams.team15}>
                {selectedPoolTeams.team15}
              </option>
              <option value={selectedPoolTeams.team16}>
                {selectedPoolTeams.team16}
              </option>
            </Form.Control>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default BracketLayout;
