import { useState, useEffect } from "react";
import { Form, Button, Container, Row, Alert } from "react-bootstrap";

import api from "../../../common/api/GetTournaments";
import "./CreatePool.module.css";

const CreatePool = ({jwt, userId, setSubmitForm, setOpen}) => {

  const [tournaments, setTournaments] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTournamentId, setSelectedTournamentId] = useState(null);
  const [tournamentIdError, setTournamentIdError] = useState("");
  const [poolName, setEnteredPoolName] = useState(null);
  const [poolNameError, setPoolNameError] = useState("");
  const [tournamentIdValidated, setTournamentIdValidated] = useState(false);
  const [nameValidated, setNameValidated] = useState(false);

  useEffect(() => {
    const fetchList = async () => {
      try {
        if (jwt && error === null) {
          const response = await api.get("/prod/tournaments", {
            headers: {
              Authorization: `${jwt}`,
            },
          });

          let validTournaments = [];
          if (
            response.hasOwnProperty("data") &&
            response.data.hasOwnProperty("Items")
          ) {
            const res = response.data.Items;
            for (const tournament of res) {
              if (validateTournamentItems(tournament) === true) {
                validTournaments.push(tournament);
              }
            }
            setTournaments(validTournaments);
          }
        }
      } catch {
        setError("We are experiencing technical difficulties. Please try again later.");
      }
    };

    fetchList();
  }, [jwt, error]);

  const tournamentOptions = tournaments.map((tournament) => {
    return (
      <option key={tournament.SK} value={tournament.SK}>
        {tournament.name}
      </option>
    );
  });

  const validateTournamentItems = (thisTournament) => {
    if (
      thisTournament.hasOwnProperty("SK") &&
      thisTournament.hasOwnProperty("name") &&
      thisTournament.hasOwnProperty("availableDate") &&
      thisTournament.hasOwnProperty("startDate") &&
      thisTournament.hasOwnProperty("endDate")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const tournamentChangeHandler = (event) => {
    const tournamentValue = event.target.value;
    if (tournamentValue !== "default") {
      setSelectedTournamentId(tournamentValue);
      setTournamentIdValidated(true);
    } else {
      setSelectedTournamentId(null);
      setTournamentIdValidated(false);
    }
  };

  const nameChangeHandler = (event) => {
    const nameLength = event.target.value.length;
    if (nameLength > 4) {
      setEnteredPoolName(event.target.value);
      setNameValidated(true);
    } else {
      setEnteredPoolName(null);
      setNameValidated(false);
    }
  };

  const findTournament = () => {
    for (const tournament of tournaments) {
      if (selectedTournamentId === tournament.SK) {
        return tournament;
      }
    }
  };

  const createPoolHandler = async (event) => {
    event.preventDefault();
    const selectTournament = findTournament();

    setTournamentIdError("");
    setPoolNameError("");
    if (tournamentIdValidated === false) {
      event.preventDefault();
      setTournamentIdError('Tournament not selected');
    }
    
    if (nameValidated === false) {
      event.preventDefault();
      setPoolNameError('Pool name must be atleast 5 characters');
    }

    if (selectedTournamentId !== null && poolName !== null) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `${jwt}`);
      myHeaders.append("Content-Type", "application/json");

      const body = JSON.stringify({
        name: poolName,
        tournamentId: selectTournament.SK,
        tournamentName: selectTournament.name,
        tournamentStartDate: selectTournament.startDate,
        tournamentEndDate: selectTournament.endDate,
        createdBy: userId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body,
      };

      const url = "https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/pools/create";
      const response = await fetch(url, requestOptions);
      if(response.status === 200) {
        
      }

      setSubmitForm(true);
      setSelectedTournamentId(null);
      setTournamentIdValidated(false);
      setEnteredPoolName(null);
      setNameValidated(false);
      setOpen(false);
    }
  };

  if (error !== null) {
    return (
      <Alert key="error" variant='danger'>
        {error}
      </Alert>
    );
  } else {
    return (
      <Container>
        <Row>
          <h1 className="title">Create A Pool</h1>
          <p className="text-danger">{tournamentIdError}</p>
          <p className="text-danger">{poolNameError}</p>
        </Row>

        <Row>
          <Form onSubmit={createPoolHandler}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="selectTournament">
                Select a tournament
              </Form.Label>
              <Form.Select
                required
                onChange={tournamentChangeHandler}
                id={selectedTournamentId}
                isValid={tournamentIdValidated}
              >
                <option value="default">Select tournament</option>
                {tournamentOptions}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="poolName">Pool Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Pool name must be at least 5 characters."
                id={poolName}
                onChange={nameChangeHandler}
                isValid={nameValidated}
              />
            </Form.Group>

            <Button type="submit">Create Pool</Button>
          </Form>
        </Row>
      </Container>
    );
  }
};

export default CreatePool;