import { useState, useEffect } from "react";
import { Container, Col, Row, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import Service from "../../common/Services/Service";
import BracketLayout from "../../components/Bracket/BracketLayout";

import classes from "./CreateBracket.module.css";

const CreateBracket = ({ jwt, userId }) => {
  const [userPools, setUserPools] = useState(null);
  const [selectedPool, setSelectedPool] = useState(null);
  const [bracketName, setBracketName] = useState(null);
  const [selectedPoolTeams, setSelectedPoolTeams] = useState({
    team1: null,
    team2: null,
    team3: null,
    team4: null,
    team5: null,
    team6: null,
    team7: null,
    team8: null,
    team9: null,
    team10: null,
    team11: null,
    team12: null,
    team13: null,
    team14: null,
    team15: null,
    team16: null,
  });
  const [validated, setValidated] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const bracketService = new Service();

    const fetchUserPools = async () => {
      if (error === null && jwt !== null) {
        try {
          const poolsList = await bracketService.getPools(
            `/prod/pools/byUser?username=${userId}`,
            jwt
          );

          const isValid = (value) => {
            const { tournamentStartDate, bracketCreated } = value;

            const currentDate = new Date();
            const offset = currentDate.getTimezoneOffset();

            const convertToLocalUTC = (date) => {
              return new Date(Date.parse(date) + offset * 60 * 1000);
            };

            const startDate = convertToLocalUTC(tournamentStartDate);

            if (startDate > currentDate || bracketCreated === false) {
              return value;
            }
          };
          const filteredTournaments = poolsList.filter(isValid);
          setUserPools(filteredTournaments);
        } catch (err) {
          setError(err);
        }
      }
    };

    if (selectedPool !== null) {
      const fetchTeams = async () => {
        if (error === null && jwt !== null) {
          try {
            const teamList = await bracketService.getTournamentDataById(
              `/prod/tournaments/data/${selectedPool[0].tournamentId}`,
              jwt
            );
            setSelectedPoolTeams({
              team1: teamList[0]["GAME-1"]["TEAM-1"],
              team2: teamList[0]["GAME-1"]["TEAM-2"],
              team3: teamList[0]["GAME-2"]["TEAM-1"],
              team4: teamList[0]["GAME-2"]["TEAM-2"],
              team5: teamList[0]["GAME-3"]["TEAM-1"],
              team6: teamList[0]["GAME-3"]["TEAM-2"],
              team7: teamList[0]["GAME-4"]["TEAM-1"],
              team8: teamList[0]["GAME-4"]["TEAM-2"],
              team9: teamList[0]["GAME-5"]["TEAM-1"],
              team10: teamList[0]["GAME-5"]["TEAM-2"],
              team11: teamList[0]["GAME-6"]["TEAM-1"],
              team12: teamList[0]["GAME-6"]["TEAM-2"],
              team13: teamList[0]["GAME-7"]["TEAM-1"],
              team14: teamList[0]["GAME-7"]["TEAM-2"],
              team15: teamList[0]["GAME-8"]["TEAM-1"],
              team16: teamList[0]["GAME-8"]["TEAM-2"],
            });
          } catch (err) {
            setError(err);
          }
        }
      };

      if (jwt !== null) {
        fetchTeams();
      }
    }

    if (jwt !== null && userId !== null) {
      fetchUserPools();
    }
  }, [
    error,
    jwt,
    userId,
    selectedPool,
    selectedPoolTeams,
    submitForm,
    userPools,
  ]);

  const handlePoolChange = (e) => {
    const { value } = e.target;
    const pool = userPools.filter((poolItem) => poolItem.SK === value);
    setSelectedPool(pool);
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setBracketName(value);
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    } else {
      e.preventDefault();
      setValidated(true);
      setSelectedPool(null);
      setBracketName(null);
    }
  };

  const renderBracket = () => {
    if (selectedPool === null) {
      return <Container></Container>;
    } else {
      return (
        <BracketLayout
          selectedPoolTeams={selectedPoolTeams}
          selectedPool={selectedPool}
          bracketName={bracketName}
          userId={userId}
          jwt={jwt}
          setSubmitForm={setSubmitForm}
        />
      );
    }
  };

  if (
    userPools === "Response status is not 200" ||
    userPools === "Cannot retrieve pools at this time."
  ) {
    return (
      <div>
        <Container>
          <h1>
            Our team is currently working on the site. Sorry for the
            inconvenience.
          </h1>
        </Container>
      </div>
    );
  } else if (userPools === "Pools are empty.") {
    return (
      <div>
        <Container style={{ padding: "100px" }}>
          <h1>Pool has not been created yet</h1>
          <Link to="/Pools">
            <Button>Create a Pool</Button>
          </Link>
        </Container>
      </div>
    );
  } else if (userPools !== null) {
    const userPoolOptions = userPools.map((pool) => {
      return (
        <option value={pool.SK} name={pool.name} key={pool.SK}>
          {pool.name}
        </option>
      );
    });

    return (
      <div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Container style={{ padding: "50px" }}>
            <h1>Welcome to your Bracket Creator!</h1>
          </Container>
          <Container>
            <Row>
              <Col md className={classes.bracketHeader}>
                <Form.Select
                  required
                  onChange={handlePoolChange}
                >
                  <option value="">Select a pool</option>
                  {userPoolOptions}
                </Form.Select>
              </Col>
              <Col md className={classes.bracketHeader}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Bracket Name"
                  onChange={handleNameChange}
                ></Form.Control>
              </Col>
            </Row>
          </Container>
        </Form>
        {renderBracket()}
      </div>
    );
  } else if (userPools === null) {
    return <Spinner></Spinner>;
  }
};

export default CreateBracket;
