import React, { useState, useEffect } from "react";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Routes, Route } from "react-router-dom";

import MainNavigation from "./components/Layout/MainNavigation/MainNavigation";
import Home from "./pages/Home/Home";
import CreateBracket from "./pages/CreateBracket/CreateBracket";
import MyPools from "./pages/Pools/MyPools";
import JoinPool from "./pages/Pools/JoinPool/JoinPool";
import ViewPool from "./pages/Pools/ViewPool/ViewPool";
import ViewBracket from "./pages/ViewBracket/ViewBracket";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

Amplify.configure(awsExports);

function App({ signOut, user }) {
  const [jwt, setJwt] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    async function loadJWT() {
      const session = await Auth.currentSession();
      const myJWT = session.idToken.jwtToken;
      const myUserId = session.accessToken.payload.username;
      setJwt(myJWT);
      setUserId(myUserId);
    }

    loadJWT();
  },[jwt, userId]);

  return (
    <div className="App">
      <MainNavigation signOut = {signOut} user = {user} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/CreateBracket" element={<CreateBracket jwt={jwt} userId={userId} />} />
        <Route path="/Pools" element={<MyPools jwt={jwt} userId={userId}/>} />
        <Route path="/Pools/:poolId" element={<ViewPool jwt={jwt}/>} />
        <Route path="/Pools/JoinPool/:poolId" element={<JoinPool  jwt={jwt} userId={userId}/>} />
        <Route path="/Brackets/:bracketId" element={<ViewBracket jwt={jwt} userId={userId}/>} />

      </Routes>
    </div>
  );
}

export default withAuthenticator(App);

