const FetchClient = {
  async get(url, jwtToken) {
    return new Promise(async (resolve, reject) => {
      try{
          const data = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${jwtToken}`,
            },
          })

          resolve(data)
      } catch (error) {
        reject(error)
      }
      
    });
  },

  async post(url, body, jwtToken) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await fetch(url, {
          method: "POST",
          headers: {
            "Authorization": `${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          redirect: 'follow',
        })

        resolve(data);
      } catch (error) {
        reject(error);
      }
    })
  },
};

export default FetchClient;
