import { Carousel } from "react-bootstrap";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./TournamentCarousel.css";

const TournamentCarousel = ({ tournaments }) => {
  const carouselItems = tournaments.map((tournament) => {
    return (
      <Carousel.Item key={tournament.SK}>
        <div className="carousel-image">
          <img
            src="https://images.unsplash.com/photo-1624252972128-31c39d18cb9f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80"
            alt="carousel"
            className="carousel"
          />
        </div>
        <Carousel.Caption>
          <h1>{tournament.name}</h1>
          <Link to="/Pools">
            <Button>Start a Pool!</Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });

  return <Carousel>{carouselItems}</Carousel>;
};

export default TournamentCarousel;
