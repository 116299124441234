import { useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import {  Clipboard2, Clipboard2Check } from "react-bootstrap-icons";

const PoolDetails = ({pool}) => {
    const [copied, setCopied] = useState(false);

    if(pool === null) {
        return <Spinner></Spinner>
    } else {
        const {SK:id, name, pin, tournamentEndDate, tournamentStartDate, tournamentName} = pool;
        const startDate = new Date( Date.parse(tournamentStartDate));
        const endDate = new Date( Date.parse(tournamentEndDate));
        const joinURl = `http://localhost:3000/Pools/JoinPool/${id}`;

        const clipboardButtonOnClickHandler = () => {
            navigator.clipboard.writeText(joinURl);
            setCopied(true);
        };

        const clipboardButton = () => {
            if (copied === true) {
                return (<Button>Copied to clipboard <Clipboard2Check/></Button>);
            } else if (copied === false) {
                return (<Button onClick={clipboardButtonOnClickHandler}> Copy Share Link <Clipboard2/></Button>);
            }
        }

        return (
            <Row>
                <Col className="pb-4">
                    <Row className="pb-4">
                        <Col>
                            <h1>{name}</h1>
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col>
                            <p>Tournament: {tournamentName}</p>
                            <p>Tournament Dates: {startDate.toDateString()} - {endDate.toDateString()}</p>
                            {clipboardButton()}
                            <p>Pin: {pin}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
};

export default PoolDetails;