import { useState, useEffect } from "react";
import { Container, Row, Spinner } from "react-bootstrap";

import Service from "../../../common/Services/Service";
import FetchClient from "../../../common/ServiceClient/FetchClient";

import TournamentItem from "../TournamentItem/TournamentItem";
import TournamentCarousel from "../TournamentCarousel/TournamentCarousel";
import "./TournamentsList.css";

const TournamentsList = () => {
  const [jwt, setJwt] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const tournamentService = new Service(FetchClient);

    const fetchJwt = async () => {
      try {
        const JWT = await tournamentService.getJwt();
        setJwt(JWT);
      } catch (err) {
        setError(err);
      }
    };

    const fetchList = async () => {
      if (error === false && jwt !== null) {
        try {
          const tournamentList = await tournamentService.getTournamentList(
            "/prod/tournaments",
            jwt
          );
          setTournaments(tournamentList);
        } catch (err) {
          setError(err);
        }
      }
    };

    fetchJwt();
    fetchList();
  }, [jwt, error]);

  const tournamentList = tournaments.map((tournament) => {
    return (
      <TournamentItem
        key={tournament.SK}
        id={tournament.SK}
        name={tournament.name}
        availableDate={tournament.availableDate}
        startDate={tournament.startDate}
        endDate={tournament.endDate}
        style={{ maxHeight: "100%", overflow: "auto" }}
      />
    );
  });

  if (error !== false) {
    return <div className="MealsError">{error}</div>;
  } else if (tournaments.length === 0) {
    return (
      <div className="Spinner">
        <Spinner animation="border" variant="info" />
      </div>
    );
  } else {
    return (
      <div>
        <TournamentCarousel tournaments={tournaments} />
        <Container>
          <Row>
            <h1 className="tournaments-title">Tournaments</h1>
          </Row>
          <Row className="scroller">
            {tournaments && <Row>{tournamentList}</Row>}
          </Row>
        </Container>
      </div>
    );
  }
};

export default TournamentsList;
