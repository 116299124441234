import { Auth } from "aws-amplify";
import FetchClient from "../ServiceClient/FetchClient";

class Service {
  constructor(httpClient) {
    this.httpClient = FetchClient;

    // Search for this.httpClient (ctrl + F)
    // Replace with FetchCLient
  }

  async getPoolById(endPoint, jwtToken) {
    try {
      const validatePoolItems = (thisPool) => {
        if (
          thisPool.hasOwnProperty("SK") &&
          thisPool.hasOwnProperty("PK") &&
          thisPool.hasOwnProperty("createdBy") &&
          thisPool.hasOwnProperty("name") &&
          thisPool.hasOwnProperty("tournamentStartDate") &&
          thisPool.hasOwnProperty("tournamentEndDate") &&
          thisPool.hasOwnProperty("createdDate") &&
          thisPool.hasOwnProperty("tournamentId") &&
          thisPool.hasOwnProperty("tournamentName")
        ) {
          return true;
        } else {
          return false;
        }
      };

      const response = await FetchClient.get(
        `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com${endPoint}`,
        jwtToken
      );

      if (response.status === 200) {
        const response_json = await response.json();
        if (response_json.hasOwnProperty("error")) {
          const error = "Cannot retrieve pools at this time.";
          return error;
        } else if (
          response_json.hasOwnProperty("Count") ||
          response_json.Count > 0
        ) {
          if (response_json.hasOwnProperty("Items")) {
            let validPools = [];
            const responseItems = response_json.Items;

            for (const pool of responseItems) {
              if (validatePoolItems(pool) === true) {
                validPools.push(pool);
              }
            }
            return validPools;
          }
        } else if (
          response_json.hasOwnProperty("Count") ||
          response_json.Count <= 0
        ) {
          const noPools = "Pools are empty.";
          return noPools;
        }
      } else {
        const error = "Response status is not 200";
        return error;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getPools(endPoint, jwtToken) {
    try {
      const validatePoolItems = (thisPool) => {
        if (
          thisPool.hasOwnProperty("name") &&
          thisPool.hasOwnProperty("bracketCreated") &&
          thisPool.hasOwnProperty("SK") &&
          thisPool.hasOwnProperty("PK") 
        ) {
          return true;
        } else {
          return false;
        }
      };

      const response = await FetchClient.get(
        `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com${endPoint}`,
        jwtToken
      );

      if (response.status === 200) {
        const response_json = await response.json();
        if (response_json.hasOwnProperty("error")) {
          const error = "Cannot retrieve pools at this time.";
          return error;
        } else if (
          response_json.hasOwnProperty("Count") &&
          response_json.Count > 0
        ) {
          if (response_json.hasOwnProperty("Items")) {
            let validPools = [];

            const responseItems = response_json.Items;

            for (const pool of responseItems) {
              if (validatePoolItems(pool) === true) {
                validPools.push(pool);
              }
            }
            return validPools;
          }
        } else {
          const error = "Cannot retrieve pools at this time.";
          return error;
        }
      } else {
        const error = "An error has occured";
        return error;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getTournamentList(endPoint, jwtToken) {
    try {
      const validateTournamentItems = (thisTournament) => {
        if (
          thisTournament.hasOwnProperty("SK") &&
          thisTournament.hasOwnProperty("name") &&
          thisTournament.hasOwnProperty("availableDate") &&
          thisTournament.hasOwnProperty("startDate") &&
          thisTournament.hasOwnProperty("endDate")
        ) {
          return true;
        } else {
          return false;
        }
      };

      const response = await FetchClient.get(
        `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com${endPoint}`,
        jwtToken
      );

      if (response.status === 200) {
        const response_json = await response.json();
        if (response_json.hasOwnProperty("error")) {
          const error = "Tournaments not found!";
          return error;
        } else if (
          response_json.hasOwnProperty("Count") &&
          response_json.Count > 0
        ) {
          if (response_json.hasOwnProperty("Items")) {
            const responseItems = response_json.Items;

            for (const tournament of responseItems) {
              if (validateTournamentItems(tournament) === true) {
                return responseItems;
              }
            }
          }
        } else {
          const error = "Cannot retrieve Tournaments at this time.";
          return error;
        }
      } else {
        const error = "An error has occured";
        return error;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getTournamentDataById(endPoint, jwtToken) {
    try {
      const validateTeamItems = (thisTeam) => {
        if (
          thisTeam.hasOwnProperty("Items") &&
          thisTeam.Items[0].hasOwnProperty("SK") &&
          thisTeam.Items[0].hasOwnProperty("data") &&
          thisTeam.Items[0].data.hasOwnProperty("GAME-1")
        ) {
          return true;
        } else {
          return false;
        }
      };

      const response = await FetchClient.get(
        `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com${endPoint}`,
        jwtToken
      );

      if (response.status === 200) {
        const response_json = await response.json();
        if (response_json.hasOwnProperty("error")) {
          const error = "Tournaments not found!";
          return error;
        } else if (
          response_json.hasOwnProperty("Count") &&
          response_json.Count > 0
        ) {
          if (response_json.hasOwnProperty("Items")) {
            let validTeams = [];
            const responseItems = response_json.Items[0].data;
            if (validateTeamItems(response_json) === true) {
                validTeams.push(responseItems);
              }
            return validTeams;
          }
        } else {
          const error = "Cannot retrieve Teams at this time.";
          return error;
        }
      } else {
        const error = "An error has occured";
        return error;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getJwt() {
    try {
      const session = await Auth.currentSession();
      if (
        session.hasOwnProperty("idToken") &&
        session.idToken.hasOwnProperty("jwtToken") &&
        session.hasOwnProperty("accessToken") &&
        session.accessToken.hasOwnProperty("payload") &&
        session.accessToken.payload.hasOwnProperty("username")
      ) {
        const jwt = session.idToken.jwtToken;
        return jwt;
      }
    } catch {
      const error = "Could not retrieve jwt.";
      return error;
    }
  }

  async getUserEmail() {
    try {
      const session = await Auth.currentSession();
      if (
        session.hasOwnProperty("idToken") &&
        session.idToken.hasOwnProperty("jwtToken") &&
        session.hasOwnProperty("accessToken") &&
        session.accessToken.hasOwnProperty("payload") &&
        session.accessToken.payload.hasOwnProperty("username")
      ) {
        const userEmail = session.idToken.payload.email;
        return userEmail;
      }
    } catch {
      const error = "Could not retrieve user email.";
      return error;
    }
  }

  async getUserName() {
    try {
      const session = await Auth.currentSession();
      if (
        session.hasOwnProperty("idToken") &&
        session.idToken.hasOwnProperty("jwtToken") &&
        session.hasOwnProperty("accessToken") &&
        session.accessToken.hasOwnProperty("payload") &&
        session.accessToken.payload.hasOwnProperty("username")
      ) {
        const userName = session.idToken.payload.username;
        return userName;
      }
    } catch {
      const error = "Could not retrieve user email.";
      return error;
    }
  }

  async createPool(endPoint, body, jwtToken) {
    try {
      const response = await FetchClient.post(
        `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com${endPoint}`,
        body,
        jwtToken
      );

      if (response.status === 200) {
        const response_json = await response.json();
        if (response_json.hasOwnProperty("error")) {
          const error = "Cannot create a pool at this time.";
          return error;
        } else {
          return response_json;
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createBracket(endPoint, body, jwtToken) {
    try {
      const response = await FetchClient.post(
        `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com${endPoint}`,
        body,
        jwtToken
      );

      if (response.status === 200) {
        const response_json = await response.json();
        if (response_json.hasOwnProperty("error")) {
          const error = "Cannot create a bracket at this time.";
          return error;
        } else {
          return response_json;
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default Service;
