import React from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./TournamentItem.css";

const TournamentItem = ({ availableDate, startDate, endDate, id, name }) => {
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();

  const convertToLocalUTC = (date) => {
    return new Date(Date.parse(date) + offset * 60 * 1000);
  };

  //Dates comming from the tournaments API call are in UTC-0 time so we need to make sure we are converting them to the local time.
  availableDate = convertToLocalUTC(availableDate);
  startDate = convertToLocalUTC(startDate);
  endDate = convertToLocalUTC(endDate);

  const showButtonHandler = () => {
    if (currentDate >= availableDate && currentDate <= endDate) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <Row className="tournament-item">
        <Col xs={2} sm={2} md={2} lg={2}>
          <img
            src="https://cdn-icons-png.flaticon.com/512/53/53283.png"
            alt="tournament"
            style={{ width: "100%", height: "10vh", objectFit: "contain" }}
          />
        </Col>
        <Col xs={6} sm={6} md={8} lg={8} style={{ textAlign: "start"}}>
          <Row>
            <h2>{name}</h2>
          </Row>
          <Row>
            <Col md>
              <h6>Brackets Open:</h6>
              <p>{availableDate.toLocaleDateString()}</p>
            </Col>
            <Col md>
              <h6>Tournament Start: </h6>
              <p>{startDate.toLocaleDateString()}</p>
            </Col>
            <Col md>
              <h6>Tournament End: </h6>
              <p>{endDate.toLocaleDateString()}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={3} sm={3} md={2} lg={2}>
          {showButtonHandler && (
            <Link to="/Pools">
              <Button variant="primary">Create Pool</Button>
            </Link>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TournamentItem;
