import {Link} from 'react-router-dom';
import {Container, Nav, Navbar} from 'react-bootstrap';
import SignOutButton from '../../../UI/buttons/SignOutButton/SignOutButton';

import './MainNavigation.css';

function MainNavigation({signOut, user}) {
  return (
    <Navbar collapseOnSelect expand="lg" className='navbar-main'>
      <Container fluid>
        <Navbar.Brand>
          <img
                src="/logo.png"
                width="70"
                height="70"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/Pools" user={user}>Pools</Nav.Link>
            <Nav.Link as={Link} to="/CreateBracket">Create a Bracket</Nav.Link>
          </Nav>
          <div className="d-flex">
            <SignOutButton signOut={signOut} />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavigation;