import TournamentsList from "../../components/Tournaments/TournamentsList/TournamentsList";
import "./Home.css";

const Home = () => {
  return (
    <div className="Home">
      <div>
        <TournamentsList />
      </div>
    </div>
  );
};

export default Home;
