import { Row, Col, Spinner } from "react-bootstrap";
import PoolListItem from "./PoolListItem";

const PoolList = ({pools}) => {
    if(pools === null) {
        return (
            <Row>
                <Col>
                    <Spinner animation="border" variant="info" />
                </Col>
            </Row>
        );
    } else if (pools.length === 0) {
        return <p>You dont have any pools yet! Create a pool and you will see it here.</p>;
    } else {
        const poolList = pools.map(pool =>  <PoolListItem key={pool.SK} pool={pool}></PoolListItem>)
        return (
            <Row>
                <Col>
                <Row>
                    <Col>
                        <h4>Name</h4>
                    </Col>
                    <Col>
                        <h4>Tournament Name</h4>
                    </Col>
                    <Col>
                        <h4>Tournament Start Date</h4>
                    </Col>
                    <Col>
                        <h4>View pool</h4>
                    </Col>
                    </Row>
                    {poolList}
                </Col>
            </Row>
        );
    }
};

export default PoolList;