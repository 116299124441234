import { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";

import PoolDetails from "../../../components/Pools/PoolDetails/PoolDetails";
import PoolBrackets from "../../../components/Pools/PoolBrackets/PoolBrackets";

const ViewPool = ({ jwt }) => {
  let { poolId } = useParams();
  const [pool, setPool] = useState(null);
  const [brackets, setBrackets] = useState(null);

  useEffect(() => {
    const fetchPool = async (jwt) => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", jwt);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/pools/${poolId}`,
          requestOptions
        );
        const responseJson = await response.json();

        if (responseJson.error) {
          setPool(null);
        } else {
          const { Items } = responseJson;
          if (Items.length > 0) {
            setPool(Items[0]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (jwt !== null) {
      fetchPool(jwt);
    }
  }, [jwt, pool, poolId]);

  useEffect(() => {
    const fetchPoolBrackets = async (jwt) => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", jwt);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/brackets/byPool?poolId=${poolId}`,
          requestOptions
        );
        const responseJson = await response.json();

        if (responseJson.error) {
          setBrackets([]);
        } else {
          setBrackets(responseJson);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (jwt !== null) {
      fetchPoolBrackets(jwt);
    }
  }, [jwt, brackets, poolId]);

  useEffect(() => {});
  return (
    <Container>
      <Row>
        <Col sm={{ span: 2, offset: 10 }}>
          <Link to="/CreateBracket">
            <Button>Create My Bracket</Button>
          </Link>
        </Col>
      </Row>
      <PoolDetails pool={pool} />
      <PoolBrackets brackets={brackets} />
    </Container>
  );
};

export default ViewPool;
