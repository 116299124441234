import { Row, Col, Button, Table, Spinner } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';

const PoolBracketDetail = ({bracket, index}) => {
    const {SK:id, name} = bracket;
    return (
            <tr key={id}>
                <td>{index}</td>
                <td>{name}</td>
                <td>0</td>
                <td> <Button variant="primary" size="sm" href={`/brackets/${id}`}>View Bracket <ArrowRightCircle/></Button></td>
            </tr>       
    );
};

const PoolBrackets = ({brackets}) => {
    if(brackets === null) {
        return <Spinner></Spinner>
    } else if (brackets.length === 0) {
        return (
            <Row className="pb-4">
                            <Col>
                                <h2>Pool Brackets</h2>
                                <p>No brackets have been made</p>
                            </Col>
                        </Row>
        );
    }else {
        if (brackets.length > 0) {
            const bracketsList = brackets.map( (bracket, index) =>  <PoolBracketDetail key={bracket.SK} bracket={bracket} index={index+1}></PoolBracketDetail>)
            return (
                <Row>
                    <Col xs={12} sm={{ span: 6, offset: 3 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
                        <Row className="pb-4">
                            <Col>
                                <h2>Pool Brackets</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>PTS</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bracketsList}
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
            );
        } else {
            return <p>No Brackets yet</p>
        }
    }
};

export default PoolBrackets;
