import { Row, Container, Col, Form, Button, Spinner, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const JoinPool = ({jwt, userId}) => {
    const {poolId} = useParams();
    const [pool, setPool] = useState(null);
    const [error, setError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [pin, setPin] = useState(null);
    const [pinError, setPinError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPoolInfo = async () => {
            if(jwt !== null && error === false) {
                try {
                    const url = `https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/pools/${poolId}`;
                    const options = {
                        method: 'GET',
                        headers: {
                            Authorization: `${jwt}`,
                          }
                    };
                    const response = await fetch(url, options);
    
                    if (response.status === 200) {
                        const response_json = await response.json();
                        if(response_json.hasOwnProperty("error")) {
                            setError('Pool not found');
                        } else if(response_json.hasOwnProperty("Count") && response_json.Count > 0) {
                            if(response_json.hasOwnProperty("Items")) {
                                setPool(response_json.Items[0]);
                            }
                        } else {
                            setError(JSON.stringify(response));
                        }
                    } else {
                        setError('Pool not found');
                    }
                } catch (error) {
                    setError('Pool not found');
                }
            }
        };

        fetchPoolInfo();
    }, [jwt, error, poolId]);

    const changePinHandler = event => {
        const pinLength = event.target.value.length;
        if(pinLength === 6) {
            setPin(event.target.value);
            setValidated(true);
        } else {
            setPin(null);
            setValidated(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (pin !== null) {
          try {
            if(pin === pool.pin) {
                const url = 'https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/pools/join';
               
                const body = {
                    "poolId": `${poolId}`,
                    "poolName": `${pool.name}`,
                    "tournamentName": pool.tournamentName,
                    "tournamentStartDate": pool.tournamentStartDate,
               };

                const options = {
                    method: 'POST',
                    headers: {
                        Authorization: `${jwt}`,
                    },
                    body: JSON.stringify(body)
                };

                const response = await fetch(url, options);
    
                if (response.status === 200) {
                    const response_json = await response.json();
                    if(response_json.hasOwnProperty("error")) {
                        setError('Pool not found');
                    } else {
                        setLoading(false);
                        setSuccess(true);

                    }   
                } else {
                    setError('Pool could not be joined.');
                }
            } else {
                setPinError('Incorrect pin. Please try again.')
            }

            setLoading(false);
          } catch (error) {
            setError(JSON.stringify(error));
          }
        }    
    };

    if(error !== false) {
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <p>ERROR: {error}</p>
                    </Col>
                </Row>
            </Container>
        ); 
    } else if(pool === null) {
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                    <Spinner animation="border" variant="info" />
                    </Col>
                </Row>
            </Container>
        );
    } else {
        return (
            <Container>
                <Collapse in={!success}>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={4}>
                            <p>Join {pool.name}</p>
                            <p>for tournament {pool.tournamentName}</p>
                            <p className="text-danger pb-2">{pinError}</p>
                            <Form className="pt-2" validated={validated} onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Control 
                                        required
                                        type="text" 
                                        placeholder="Enter the pool pin."
                                        id="pin"
                                        minLength={6}
                                        onChange={changePinHandler}
                                    />
                                </Form.Group>
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading? 'Loading..' : 'Submit'}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Collapse>
                <Collapse in={success}>
                    <Row>
                        <Col>
                            <p>Joined Pool!</p>
                            <Button href="/Pools"> View My Pools</Button>
                        </Col>
                    </Row>
                </Collapse>
            </Container>
        );
    }
};

export default JoinPool;