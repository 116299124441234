import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Collapse } from "react-bootstrap";

import PoolList from "../../components/Pools/UserPoolsList/PoolList";
import CreatePool from "../../components/Pools/CreatePool/CreatePool";

const MyPools = ({jwt, userId}) => {
  const [open, setOpen] = useState(false);
  const [pools, setPools] = useState(null);
  const [submitForm, setSubmitForm] = useState(false);

  useEffect(() => {
    const fetchUserPools = async (jwt, userId) => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", jwt);
      
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      try {
        const response = await fetch(`https://0ph0ivh7pe.execute-api.us-east-1.amazonaws.com/prod/pools/byUser?username=${userId}`, requestOptions);
        const responseJson = await response.json();

        if(response.status === 404) {
          setPools([]);
        } else {
          const {Items} = responseJson;
          setPools(Items);
        }

      } catch (error) {
        console.log(error);
      }
    };

    if (jwt !== null && userId !== null) {
      fetchUserPools(jwt, userId);
    }
  },[jwt, userId, submitForm]);

  return (
    <Container fluid>
      <Row>
        <Col md={{span:2, offset:10}}>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={!open}
            className="btn btn-success"
          >
            Create Pool
          </Button>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col>
          <h1 >My Pools</h1>
        </Col>
      </Row>
      <Row>
        <Collapse in={open}>
          <div>
            <CreatePool jwt={jwt} userId={userId} setSubmitForm={setSubmitForm} setOpen={setOpen}/>
          </div>
        </Collapse>
      </Row>
      <PoolList pools={pools}/>
    </Container>
  );
};

export default MyPools;